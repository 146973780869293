import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    addbtn: {
        width: '100%',
        color: 'white',
        background: '#14d992',
        minHeight: '50px',
        "&:hover":{
            background: '#14d992',
        },
        "& .MuiButton-label":{
            pointerEvents:'none',
        }
    },
    imgdevices:{
        width: "100%",
        height:"300px"
    }
});
