import { Button } from '@material-ui/core';

import { CallMissedSharp } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getAllUsers } from '../../../services/userService/userService';
import GenericTable from '../../atoms/table/table';
import AddUser from './addUser';
import { useStyles } from './user.style';
import AppBarDashboard from '../../molecules/appBar/appBar';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';
import { DeleteUser } from '../../../services/userService/userService';


const UserGrid = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const nevigate = useHistory();
    const [allrows, setallrows] = React.useState([]);
    const CallbackData = data => {
        setallrows(data);
    }
    const headCells = [
        { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
        { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
        { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
        { id: 'phone', numeric: false, disablePadding: true, label: 'Phone' },
        { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
        { id: 'user_type', numeric: false, disablePadding: false, label: 'User Type' },
        { id: 'gender', numeric: false, disablePadding: false, label: 'Gender' },

    ];
    const edituser = (event, selected) => {
        debugger;
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one user", "warning"));
        }
        else if (selected.length === 1) {
            nevigate.push('/adduser?id=' + selected.toString());
        }
    }
    const successdel = data => {
        if (data.status) {
            dispatch(showSuccessSnackbar(data.message, "success"));
        }
        else {
            dispatch(showSuccessSnackbar(data.message, "error"));
            getAllUsers(CallbackData);
        }
    }
    const deleteuser = (event, selected) => {
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one User", "warning"));
        }
        else {
            DeleteUser(selected.toString(), successdel)
        }
    }
    useEffect(() => {
        getAllUsers(CallbackData);
    }, [successdel]);
    return (
        <AppBarDashboard>
            <div>
                <Link to="/adduser">
                    <Button
                        varient="contained"
                        className={classes.addbtn}
                        size="large"
                    >
                        Add User
                    </Button>
                </Link>

                <GenericTable
                    rows={allrows}
                    headCells={headCells}
                    gridname="Users"
                    onedit={edituser}
                    handledelete={deleteuser}
                />
            </div>
        </AppBarDashboard>

    );
}
export default UserGrid;