import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useStyles } from "./packageItem.style";


const Item = ({ item, handleAddToCart }) => {
    const classes = useStyles();
    const [classesforhighlight, setpkgid] = React.useState([]);
    return (
        <div className={classes.Wrapper}>
            <div className={classes.topName}>
                {item.name}
            </div>
            <div className={classes.feature}>
                Feature
            </div>
            <Grid container className={classes.featureGridCont} spacing={3}>
                <Grid className={classes.girdhead} item xs={8}>Feature</Grid>
                <Grid className={classes.girdhead} item xs={4}>Price</Grid>
                {item.features.map(x => {
                    return (
                        <>
                            <Grid item xs={8}>{x.name}</Grid>
                            <Grid item xs={4}>{x.price}</Grid>
                        </>
                    )
                })}
            </Grid>

            <Button
                name="packageSelectionBtn"
                variant="contained"
                className={classes.addbtn}
                onClick={(event) => {
                    handleAddToCart(item);
                    debugger;
                    const optionName = document.getElementsByName("packageSelectionBtn");
                    for (let i = 0; i < optionName.length; i++) {
                        const element = optionName[i];
                        element.style.background = '#14d992';
                    }
                    event.target.style.background = 'indianred';                    
                }
                }
            >
                {item.price}
            </Button>
        </div>
    );
};

export default Item;