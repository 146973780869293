import { Button, Grid } from "@material-ui/core";
import { mergeClasses } from "@material-ui/styles";
import { useStyles } from "./cartItem.style";



const CartItem = ({ item, handleAddToCart }) => {
    const classes = useStyles();
    debugger;
    return (
        <div>
            <img className={classes.imgdevices} src={item.images[0]} alt={item.title} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <p>{item.description}</p>
                </Grid>
                <Grid item xs={6}>
                    <h3>${item.price}</h3>
                </Grid>
            </Grid>
            <Button className={classes.addbtn} name="btnSelectionForCart" onClick={(event) => {
                const optionName = document.getElementsByName("btnSelectionForCart");
                for (let i = 0; i < optionName.length; i++) {
                    const element = optionName[i];
                    element.style.background = '#14d992';
                }
                event.target.style.background = 'indianred';
                handleAddToCart(item);
            }
            }>Add to cart</Button>
        </div>
    );
};

export default CartItem;
