import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    Wrapper: {
        boxShadow: '1px 1px 1px 1px #d2d2d2'
    },
    topName: {
        background: '#5243aa',
        color: 'white',
        textAlign: 'center',
        padding: '10px',
        fontSize: '18px',
    },
    feature: {
        fontSize: '22px',
        textAlign: 'center',
        borderBottom: '2px solid',
        paddingBottom: '6px',
        margin: '10px',
    },
    girdhead: {
        fontWeight: '600',
    },
    featureGridCont: {
        padding: '10px'
    },
    addbtn: {
        width: '100%',
        color: 'white',
        background: '#14d992',
        minHeight: '50px',
        "&:hover": {
            background: '#14d992',
        },
        "& .MuiButton-label":{
            pointerEvents:'none',
        }
    },
    selectedPkg: {
    background: 'indianred !important',
}
});