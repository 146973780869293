import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useStyles } from "./subscription.style";
import { Button } from "@material-ui/core";
import Input from "../../atoms/input/input";

const PaymentMethods = ({ totalAmount, checkOutCash, cardCheckOut }) => {
  const classes = useStyles();
  const [paymentMethod, setpaymentMethod] = React.useState("");
  const handleChangePaymentMethod = (event) => {
    debugger;
    setpaymentMethod(event.target.value);
  };
  return (
    <>
      <h3>Your Order Total</h3>
      <h3>{totalAmount}</h3>
      <RadioGroup
        row
        aria-label="position"
        id="PaymentMethods"
        value={paymentMethod}
        onChange={handleChangePaymentMethod}
      >
        <FormControlLabel
          value="cash"
          control={<Radio color="primary" />}
          label="Cash"
          labelPlacement="start"
        />
        <FormControlLabel
          value="card"
          control={<Radio color="primary" />}
          label="Card"
          labelPlacement="start"
        />
      </RadioGroup>
      {paymentMethod == "cash" ? (
        <CashSubmit totalAmount={totalAmount} checkOutCash={checkOutCash} />
      ) : (
        ""
      )}
      {paymentMethod == "card" ? (
        <CardSubmit cardCheckOut={cardCheckOut} />
      ) : (
        ""
      )}
    </>
  );
};
const CashSubmit = ({ totalAmount, checkOutCash }) => {
  const classes = useStyles();

  const handlechange = () => {};
  return (
    <>
      <Input
        id="CashAmount"
        type="text"
        value={totalAmount}
        labeltext="Total Received Cash"
        changehandler={handlechange}
      />
      <Button
        className={classes.nxtbtn}
        variant="contained"
        onClick={checkOutCash}
      >
        Submit
      </Button>
    </>
  );
};
const CardSubmit = ({ cardCheckOut }) => {
  const classes = useStyles();
  const [CardType, setCardType] = React.useState("");
  const handleChangeCardType = (event) => {
    debugger;
    setCardType(event.target.value);
  };
  return (
    <>
      <h3>Please Select a Card Type</h3>
      <RadioGroup
        row
        aria-label="position"
        id="CardType"
        value={CardType}
        onChange={handleChangeCardType}
      >
        <FormControlLabel
          value="debit"
          control={<Radio color="primary" />}
          label="Debit Card"
          labelPlacement="start"
        />
        <FormControlLabel
          value="credit"
          control={<Radio color="primary" />}
          label="Credit Card"
          labelPlacement="start"
        />
      </RadioGroup>
      {CardType !== "" ? (
        <Button
          className={classes.nxtbtn}
          variant="contained"
          onClick={() => {
            cardCheckOut(CardType);
          }}
        >
          Proceed
        </Button>
      ) : (
        ""
      )}
    </>
  );
};
export default PaymentMethods;
