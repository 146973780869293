import { makeStyles } from '@material-ui/core/styles';
import { NoEncryption } from '@material-ui/icons';

export const useStyles = makeStyles({
    packgCard: {
    },
    cardtable: {
        border: '1px solid lightgray',
        padding: '8px',
    },
    EntityCard: {
        marginTop: '15px',
    },
    Subscriptcard: {
        marginTop: '15px',
    },
    beanchtable: {
        marginTop: '15px',
    },
    ActionContainer: {
        float: 'right',
    },
    spacingletter: {
        marginTop: '5px',
    },
    addbtn: {
        marginBottom: '30px',
        backgroundColor: '#5243AA',
        color: '#fff',
        marginRight: '5px',
        textDecoration: 'none',
        '&:hover': {
            marginBottom: '30px',
            backgroundColor: '#5243AA',
            color: '#fff',
        }
    },
    qrcode: {
        textAlign: 'center',
    },
    root: {
        '& .MuiAppBar-colorPrimary': {
            backgroundColor: '#5243aa',
        }
    },
    nxtbtn: {
        float: 'right',
        background: '#5243aa',
        color: 'white',
        marginTop: '10px'
    },
    deviceBox: {
        textAlign: 'center',
    },
    packageCheckoutHeader: {
        border: '1px solid lightgray',
        background: '#f7f6f6',
        padding: '10px',
    },
    formControlDropDown: {
        width: '100%',
    },
    submissionBtn:{
        display: 'none',
        marginRight: '10px',
    },
    ShoppingCartButton: {
        color:'black',
        fontSize: '40px',
        float:'right'
    },
    AddedBranches: {
        '& .MuiGrid-grid-xs-4':{
            border: '1px solid lightgray'
        }
    },
    btnSaveBranch: {
        marginTop:'32px',
        marginLeft: '10px'
    },
    NavTabClass:{
        pointerEvents: 'none'
    }
});

