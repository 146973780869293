import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Input from '../../atoms/input/input';
import Btn from '../../atoms/button/button';
import DropDown from '../../atoms/dropdown/dropdown';
import { useStyles } from './subscription.style';
import { hideSplashScreen, showSplashScreen } from '../../../helpers/splashScreen';
import AppBarDashboard from '../../molecules/appBar/appBar';
import { createFeature, getAllStatus } from '../../../services/featurePackageService/featurePackageService';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';
import { createEntity } from '../../../services/subscriptionService/subscriptionService';
import { Button } from '@material-ui/core';

const EntityDetails = ({ pkgid, getentityid, setNext }) => {
    const dispatch = useDispatch();
    const nevigate = useHistory();
    const classes = useStyles();
    const [descriptionerror, setdescriptionerror] = useState("");
    const [descriptionvalidation, setdescriptionvalidation] = useState("");
    const [priceerror, setpriceerror] = useState("");
    const [pricevalidation, setpricevalidation] = useState("");
    const [valuestatus, setvaluestatus] = useState("");
    const [isTaxable, setisTaxable] = React.useState("");
    const [statusdropdown, setstatusdropdown] = useState([]);
    const formik = useFormik({
        initialValues: {
            commercialRegNo: '',
            businessName: '',
            businessOwnerName: '',
            ownerNo: '',
            businessAddress: '',
            businessCity: '',
            businessDistrict: '',
            businessCountry: '',
            businessNumber: '',
            isTaxable: '',
            vatid: '',
            emailAddress: '',
        },
        validationSchema: Yup.object({
            emailAddress: Yup.string().email('Invalid email address').required('Required'),
        }),
        onSubmit: values => {
            //showSplashScreen();
            values.isTaxable = isTaxable;
            createEntity(values, handlesuccessAdd, pkgid.toString())
            //alert(JSON.stringify(values, null, 2));
        },
    });
    const handlesuccessAdd = (data) => {
        debugger;
        if (data.status) {
            dispatch(showSuccessSnackbar("Entity Added successfully", "success"));
            getentityid(data.data[0].entity_id);
            hideSplashScreen();
            setNext("2");
        }
        else {
            dispatch(showSuccessSnackbar(data.message, "error"));
            hideSplashScreen();

        }

    }
    const handlechange = () => {

    }
    const handlechangestatus = (event) => {
        setvaluestatus(event.target.value);
    }
    const getStatusData = data => {
        setstatusdropdown(data);
    }
    const handlechangeisTaxable = event => {
        debugger;
        setisTaxable(event.target.value);
    }
    useEffect(() => {
        getAllStatus(getStatusData);
    }, []);
    return (
        <div>
            <h5>Entity Details : Commercial Registration No, Details of business and its Owner Details etc.</h5>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('commercialRegNo')}
                            id="commercialRegNo"
                            type="text"
                            labeltext="Commercial Registration #"
                            changehandler={handlechange}
                        //helperText={descriptionerror}
                        // error={descriptionvalidation}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Input
                            {...formik.getFieldProps('businessName')}
                            id="businessName"
                            labeltext="Legal Business Name"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('businessOwnerName')}
                            id="businessOwnerName"
                            labeltext="Business Owner Name"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('ownerNo')}
                            id="ownerNo"
                            labeltext="Owner Contact #"
                            changehandler={handlechange}
                            type="number"
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('businessAddress')}
                            id="businessAddress"
                            labeltext="Business Address"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('businessCity')}
                            id="businessCity"
                            labeltext="Business City"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('businessDistrict')}
                            id="businessDistrict"
                            labeltext="Business District"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('businessCountry')}
                            id="businessCountry"
                            labeltext="Business Country"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('businessNumber')}
                            id="businessNumber"
                            type="number    "
                            labeltext="Business Contact #"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <label>Is Taxable</label>
                        <RadioGroup row aria-label="position" id="isTaxable" {...formik.getFieldProps('isTaxable')} value={isTaxable} onChange={handlechangeisTaxable}>
                            <FormControlLabel
                                value="Y"
                                control={<Radio color="primary" />}
                                label="Yes"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="N"
                                control={<Radio color="primary" />}
                                label="No"
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('vatid')}
                            id="vatid"
                            labeltext="VAT ID #"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('emailAddress')}
                            id="emailAddress"
                            labeltext="Email Address"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    id="EntitySubmission"
                    clickhandler={handlechange}
                    className={classes.submissionBtn}
                >
                    Save
                </Button>
            </form>
        </div>
    )

}

export default EntityDetails;