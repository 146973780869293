import React from 'react';
import { setSessionStorageItem, getSessionStorageItem } from '../../helpers/utility'


export function getAllUsers(cb, id) {
    let URL = window.APP.CONFIGURATION.API + "fetch/users";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function getUserbyId(cb, id) {
    let URL = window.APP.CONFIGURATION.API + "fetch/user/" + id;
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data[0]);
            }
        });
}
export function getAllRolesPrivileges(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/privileges";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function getAllRolesPrivilegeByID(cb, id) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/privileges/" + id;
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data[0]);
            }
        });
}
export function getAllModules(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/modules";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function getAllUserType(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/sys-code/UTP";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function getAllPrivilegeType(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/sys-code/ATYP";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function getMenusofModule(id, cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/module-menus/" + id;
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function getAllAccountType(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/sys-code/ATYP";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function getAllUserPrivileges(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/privileges";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function createUser(user, cb, isEdit, id) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/user";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(
            {
                "operation": isEdit ? "E" : "C",
                "user_id": id,
                "email": user.email,
                "phone": user.phone,
                "name": user.userName,
                "privilege": user.privileges,
                "gender": user.gender,
                "user_type": user.userType,
                "account_type": user.accountType,
            }
        )
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)

            cb(data);
        });
}
export function createPrivilege(privilege, cb, isEdit) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/privilege";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({
            "operation": isEdit ? "E" : "C",
            "description": privilege.description,
            "status": "C",
            "type": privilege.privilegeType,
        })
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                AddMenuToPrv(data.data[0].privilege_id, privilege.menus, cb);
            }
            else {
                cb(data);
            }
        });
}
export function AddMenuToPrv(privilegeid, menus, callback) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/privilege";
    let token = getSessionStorageItem('token');
    let menuarry = [];
    menus.forEach(element => {
        menuarry.push(
            {
                "menu_id": element,
                "operation": "C"
            },
            {
                "menu_id": element,
                "operation": "V"
            },
            {
                "menu_id": element,
                "operation": "V"
            },
            {
                "menu_id": element,
                "operation": "E"
            });
    });
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({
            "operation": "A",
            "privilege_id": privilegeid,
            "menu_ops": menuarry
        })
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data) {
                callback(data);
            }
        });
}
export function DeletePrivilege(id, cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/privilege";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({
            "operation": "D",
            "privilege_id": id
        })
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            cb(data);
        });
}
export function DeleteUser(id, cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/user";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(
            {
                "operation": "D",
                "user_id": id
            }
        )
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            cb(data);
        });
}