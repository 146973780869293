import React from 'react';
import { useStyles } from './subscription.style';

const CheckoutSummary = ({packg, device}) => {
    debugger;
    const classes = useStyles();
    return (
        <>
            <h2>Your Order Summary!</h2>
            <h3 className={classes.packageCheckoutHeader}>Package Details</h3>
            <h4>{packg.name}</h4>
            <h4>{packg.price}</h4>
            <h4 className={classes.packageCheckoutHeader}>Your Devices</h4>
            <h4>{device.description}</h4>
        </>
    )
}

export default CheckoutSummary;