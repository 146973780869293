import React from 'react';
import Button from '@material-ui/core/Button';
import { useStyles } from './subscription.style';
import { getAllPackagesForSubscription } from '../../../services/subscriptionService/subscriptionService';
import { useEffect } from 'react';
import GenericTable from '../../atoms/table/table';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import Item from '../../atoms/packageItem/packageItem';

const PackageDetails = ({ packgid, pkg }) => {
    const classes = useStyles();
    const nevigate = useHistory();
    const dispatch = useDispatch();
    const [allrows, setallrows] = React.useState([]);
    const successGetSubs = data => {
        debugger;
        setallrows(data);
    }
    const AddSubsPackage = (event, selected) => {
        debugger;
        if (selected.length > 1) {
            dispatch(showSuccessSnackbar("Please select only one user", "warning"));
        }
        else if (selected.length === 1) {

        }
    }
    const getpackgId = (item) => {
        packgid && packgid(item.id);
        pkg && pkg(item);
    }
    const headCells = [
        { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
        { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
        { id: 'features', numeric: false, disablePadding: true, label: 'Features' },
        { id: 'price', numeric: false, disablePadding: true, label: 'Price' },
    ];
    useEffect(() => {
        getAllPackagesForSubscription(successGetSubs);
    }, []);
    return (
        <div>
            {/* <GenericTable rows={allrows} headCells={headCells} gridname="Please Select a Package for subscription" setselctd={packgid} /> */}
            <Grid container spacing={10}>
                {allrows.map(x => {
                    debugger;
                    return (
                        <>
                            <Grid item xs={4}>
                                <Item item={x} handleAddToCart={getpackgId} />
                            </Grid>
                        </>
                    )
                })}
            </Grid>
        </div>
    );
};
export default PackageDetails;
