import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Input from '../../atoms/input/input';
import Btn from '../../atoms/button/button';
import { useStyles } from './subscription.style';
import DropDown from '../../atoms/dropdown/dropdown';
import { hideSplashScreen, showSplashScreen } from '../../../helpers/splashScreen';
import AppBarDashboard from '../../molecules/appBar/appBar';
import { createFeature, getAllStatus } from '../../../services/featurePackageService/featurePackageService';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';
import { getAllCatalogyeType, getAllSourceType, getStoreConfig, createBranch, getEntityBranches } from '../../../services/subscriptionService/subscriptionService';


const BranchDetails = ({ entityid, setNext, setAllBranches }) => {
    const dispatch = useDispatch();
    const nevigate = useHistory();
    const classes = useStyles();
    const [descriptionerror, setdescriptionerror] = useState("");
    const [descriptionvalidation, setdescriptionvalidation] = useState("");
    const [priceerror, setpriceerror] = useState("");
    const [pricevalidation, setpricevalidation] = useState("");
    const [valuestatus, setvaluestatus] = useState("");
    const [statusdropdown, setstatusdropdown] = useState([]);
    const [storeType, setstoreType] = React.useState('');
    const [storeTypedropdown, setstoreTypedropdown] = useState([]);
    const [productCategory, setproductCategory] = React.useState('');
    const [productCategorydropdown, setproductCategorydropdown] = useState([]);
    const [catalogType, setcatalogType] = React.useState('');
    const [catalogTypedropdown, setcatalogTypedropdown] = useState([]);
    const [soruceType, setsoruceType] = React.useState('');
    const [soruceTypedropdown, setsoruceTypedropdown] = useState([]);
    const [storeConfig, setstoreConfig] = useState([]);
    const successGetAllBranches = data => {
        setAllBranches(data.data);
    }
    const handlesuccessAdd = (data) => {
        if (data.status) {
            dispatch(showSuccessSnackbar("Branch Added successfully", "success"));
            hideSplashScreen();
            getEntityBranches(successGetAllBranches,entityid);
        }
        else {
            dispatch(showSuccessSnackbar(data.message, "error"));
            hideSplashScreen();

        }

    }

    const formik = useFormik({
        initialValues: {
            branchdescription: '',
            branchGeoLocation: '',
            branchAddress: '',
            branchstreetName: '',
            branchcity: '',
            branchdistrict: '',
            branchZip: 0,
            branchUnit: '',
            buildingNo: '',
            additionalNo: '',
            storeType: '',
            catalogType: '',
            productCategory: '',
            soruceType: '',
            operatorName: '',
            operatorIdNo: '',
            operatorMobileNo: '',
            operatorEmail: '',
            managerName: '',
            managerMobileNo: '',
        },
        validationSchema: Yup.object({
        }),
        onSubmit: values => {
            debugger;
            // showSplashScreen();
            values.storeType = storeType;
            values.productCategory = productCategory;
            createBranch(values, handlesuccessAdd, entityid.toString());
            //alert(JSON.stringify(values, null, 2));

        },
    });
    const handlechange = () => {

    }
    const handlestoreType = (event) => {
        debugger;
        setstoreType(event.target.value);
        let productcat = storeTypedropdown.find(x => x.typ_id === event.target.value);
        setproductCategorydropdown(productcat.categories);
    }
    const handleproductCategory = (event) => {
        setproductCategory(event.target.value);
    }
    const hanclecatalogType = (event) => {
        debugger;
        setcatalogType(event.target.value);
    }
    const handlesoruceType = (event) => {
        setsoruceType(event.target.value);
    }
    const handlechangestatus = (event) => {
        setvaluestatus(event.target.value);
    }
    const getStatusData = data => {
        setstatusdropdown(data);
    }
    const getCatalogTypeSuccess = data => {
        setcatalogTypedropdown(data);
    }
    const getSourceTypeSuccess = data => {
        setsoruceTypedropdown(data);
    }
    const successStoreConfig = data => {
        debugger;
        setstoreTypedropdown(data);
    }


    useEffect(() => {
        getAllStatus(getStatusData);
        getAllCatalogyeType(getCatalogTypeSuccess);
        getAllSourceType(getSourceTypeSuccess);
        getStoreConfig(successStoreConfig);
    }, []);
    return (
        <div>
            <h5>Branch Details : Branch, Store Type & Operators can be multiple for single entity.</h5>
            <form onSubmit={formik.handleSubmit} id="branchform">
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('branchdescription')}
                            id="branchdescription"
                            type="text"
                            labeltext="Branch Description"
                            changehandler={handlechange}
                        //helperText={descriptionerror}
                        // error={descriptionvalidation}
                        />
                    </Grid>
                </Grid>
                <h4>Branch Address</h4>
                <Grid container spacing={5}>
                    <Grid item xs={4}>
                        <Input
                            {...formik.getFieldProps('branchGeoLocation')}
                            id="branchGeoLocation"
                            labeltext="Branch Geo Location"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Input
                            {...formik.getFieldProps('branchAddress')}
                            id="branchAddress"
                            labeltext="Branch Address"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('branchstreetName')}
                            id="branchstreetName"
                            labeltext="Street Name"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('branchcity')}
                            id="branchcity"
                            labeltext="City"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('branchdistrict')}
                            id="branchdistrict"
                            labeltext="District"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('branchZip')}
                            id="branchZip"
                            labeltext="Zip"
                            type="number"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('branchUnit')}
                            id="branchUnit"
                            labeltext="Unit"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('buildingNo')}
                            id="buildingNo"
                            labeltext="Building #"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('additionalNo')}
                            id="additionalNo"
                            labeltext="Additional #"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                </Grid>
                <h4>Store Information</h4>
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <DropDown
                            valuedropdown={storeType}
                            setvaluedropdown={setstoreType}
                            changehandler={handlestoreType}
                            id="storeType"
                            labeltext="Store Type"
                            dropdownitems={storeTypedropdown}
                        //{...formik.getFieldProps('storeType')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DropDown
                            valuedropdown={productCategory}
                            setvaluedropdown={setproductCategory}
                            changehandler={handleproductCategory}
                            id="productCategory"
                            labeltext="Product Category"
                            dropdownitems={productCategorydropdown}
                        //{...formik.getFieldProps('productCategory')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DropDown
                            valuedropdown={catalogType}
                            setvaluedropdown={setcatalogType}
                            changehandler={hanclecatalogType}
                            id="catalogType"
                            labeltext="Catalog Type"
                            dropdownitems={catalogTypedropdown}
                            {...formik.getFieldProps('catalogType')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DropDown
                            valuedropdown={soruceType}
                            setvaluedropdown={setsoruceType}
                            changehandler={handlesoruceType}
                            id="soruceType"
                            labeltext="Source Type"
                            dropdownitems={soruceTypedropdown}
                            {...formik.getFieldProps('soruceType')}
                        />
                    </Grid>
                </Grid>
                <h4>Operator Information</h4>
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('operatorName')}
                            id="operatorName"
                            labeltext="Operator Name"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>

                        <Input
                            {...formik.getFieldProps('operatorIdNo')}
                            id="operatorIdNo"
                            labeltext="Operator ID No"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>

                    <Grid item xs={6}>

                        <Input
                            {...formik.getFieldProps('operatorMobileNo')}
                            id="operatorMobileNo"
                            labeltext="Operator Mobile Number"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>

                    <Grid item xs={6}>

                        <Input
                            {...formik.getFieldProps('operatorEmail')}
                            id="operatorEmail"
                            labeltext="Operator Email"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>

                </Grid>
                <h4>Manager Information</h4>
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <Input
                            {...formik.getFieldProps('managerName')}
                            id="managerName"
                            labeltext="Manager Name"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>
                    <Grid item xs={6}>

                        <Input
                            {...formik.getFieldProps('managerMobileNo')}
                            id="managerMobileNo"
                            labeltext="Manager Mobile Number"
                            changehandler={handlechange}
                        //helperText={priceerror}
                        //error={pricevalidation}
                        />
                    </Grid>

                </Grid>
                <Button
                    type="submit"
                    id="BranchSubmission"
                    clickhandler={handlechange}
                    className={[classes.nxtbtn, classes.btnSaveBranch]}
                >
                    Save
                </Button>
            </form>
        </div>
    )

}

export default BranchDetails;