import React from 'react';
import { hideSplashScreen } from '../../helpers/splashScreen';
import { setSessionStorageItem, getSessionStorageItem } from '../../helpers/utility';
export function GetSubscriptionList(cb) {
    debugger;
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    let URL = window.APP.CONFIGURATION.API + 'fetch/subscriptions';
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            if (data.status) {
                cb(data);

            }
        });

}



export function getSubscriptionDetail(cb, id) {
    debugger;

    let URL = window.APP.CONFIGURATION.API + "fetch/subscription/" + id;
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data);
            }
        });
}
export function getAllPackagesForSubscription(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/packages";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            if (data.status) {
                console.log(data)
                cb(data.data);
            }

        });
}
export function createEntity(entity, cb, packageid) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/entity";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(
            {
                "operation": "C",
                "attachments": [],
                "cr_number": entity.ownerNo,
                "owner_name": entity.businessOwnerName,
                "address": entity.businessAddress,
                "city": entity.businessCity,
                "district": entity.businessDistrict,
                "country": entity.businessCountry,
                "vat_id": entity.vatid,
                "email": entity.emailAddress,
                "status": "C",
                "contact_number": entity.businessNumber,
                "business_name": entity.businessName,
                "package_id": packageid,
                "is_taxable": entity.isTaxable,

            }
        )
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)

            cb(data);
        });
}
export function getAllCatalogyeType(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/sys-code/CTL";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function getAllSourceType(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/sys-code/SRC";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function getStoreConfig(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/store-config";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function createBranch(branch, cb, entityid) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/branch";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(
            {
                "operation": "C",
                "entity_id": entityid,
                "status": "C",
                "branch_details": [
                    {
                        "branch_description": branch.branchdescription,
                        "address": branch.branchAddress,
                        "building": branch.buildingNo,
                        "street": branch.branchstreetName,
                        "city": branch.branchcity,
                        "district": branch.branchdistrict,
                        "zip": branch.branchZip,
                        "unit": branch.branchUnit,
                        "latitude": 0,
                        "longitude": 0,
                        "store": {
                            "type": branch.storeType,
                            "category": branch.productCategory,
                            "catalog_type": branch.catalogType,
                            "source_type": branch.soruceType
                        },
                        "manager": {
                            "name": branch.managerName,
                            "mobile_number": branch.managerMobileNo
                        },
                        "operator": {
                            "name": branch.operatorName,
                            "id_number": branch.operatorIdNo,
                            "mobile_number": branch.operatorMobileNo,
                            "email": branch.operatorEmail
                        }
                    }
                ]
            }
        )
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            cb(data);
        });
}
export function getDevices(cb) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/peripherals";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data.data);
            }
        });
}
export function createMarketPlace(marketPlace, cb, entityid) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/entity-peripheral";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(
            {
                "operation": "C",
                "entity_id": entityid,
                "peripherals": [
                    {
                        "id": marketPlace.id,
                        "sku": marketPlace.sku,
                        "price": marketPlace.price,
                        "quantity": "1",
                    }
                ],
                payment_type: "F",
            }
        )
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            cb(data);
        });
}
export function saveOnlyCheckOutSub(total, cb, entityid) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/subscription";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(
            {
                "operation" : "C",
                "entity_id": entityid,
                "subscription_total" : total,
                "payment_status": "P"
            }
        )
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            cb(data);
        });
}
export function cashCheckOutSub(total, cb, entityid) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/subscription";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(
            {
                "operation" : "C",
                "entity_id": entityid,
                "subscription_total" : total,
                "payment_status": "P"
            }
        )
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            cb(data);
        });
}
export function cardCheckOutSub(total, cb, card) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "proc/process-payment";
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(
            {
                "card_type": card,
                "amount": total
            }
        )
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            cb(data);
        });
}
export function getEntityBranches(cb, id) {
    debugger;
    let URL = window.APP.CONFIGURATION.API + "fetch/entity-branches/" + id;
    let token = getSessionStorageItem('token');
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    };
    fetch(URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            debugger;
            console.log(data)
            if (data.status) {
                cb(data);
            }
        });
}