import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Input from "../../atoms/input/input";
import Btn from "../../atoms/button/button";
import DropDown from '../../atoms/dropdown/dropdown';
import { useStyles } from './rolePrivileges.style';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { createPrivilege, getAllModules, getAllPrivilegeType, getAllRolesPrivilegeByID, getMenusofModule } from '../../../services/userService/userService';
import AppBarDashboard from '../../molecules/appBar/appBar';
import { showSuccessSnackbar } from '../../../stores/actions/snackBarActions';
const queryString = require('query-string');

const AddPrivileges = () => {
    const parsed = queryString.parse(window.location.search);
    let { id } = parsed;
    const nevigate = useHistory();
    const dispatch = useDispatch();
    const [descriptionerror, setdescriptionerror] = React.useState("");
    const [descriptionValid, setdescriptionValid] = React.useState(false);
    const [privilegeType, setprivilegeType] = React.useState('');
    const [modules, setmodules] = React.useState('');
    const [menus, setmenus] = React.useState([]);
    const [moduledropdown, setmoduledropdown] = React.useState([]);
    const [privilegedropdown, setprivilegedropdown] = React.useState([]);
    const [menudropdown, setmenudropdown] = React.useState([]);

    const formik = useFormik({
        initialValues: {
            description: '',
            privilegeType: '',
            modules: '',
            menus: []
        },
        validationSchema: Yup.object({
            description: Yup.string().required('Required'),

            //            email: Yup.string().email('Invalid email address').required('Required'),
        }),
        onSubmit: values => {
            //alert(JSON.stringify(values, null, 2));
            if (id && id !== '') {
                createPrivilege(values, successprivilegeAdded, true)
            }
            else {
                createPrivilege(values, successprivilegeAdded, false)
            }
        },
        handleChange: event => {
            debugger;
        },
    });
    const handlechange = () => {
        debugger;
    }
    const handlechangeprivilegeType = () => {
        debugger;
    }
    const successprivilegeAdded = data => {
        if (data.status) {
            dispatch(showSuccessSnackbar(data.message, "success"));
            nevigate.push("/rolesprivileges");
        }
        else {
            dispatch(showSuccessSnackbar(data.message, "error"));
        }

    }
    const handlemodules = (event) => {
        debugger;
        setmodules(event.target.value);
        let moduleid = event.target.value;
        getMenusofModule(moduleid, successMenu);

    }
    const successMenu = data => {
        debugger;
        setmenudropdown(data[0].menus);
    }
    const handlemenus = (event) => {
        debugger;
        setmenus(event.target.value);
    }
    const handleclick = () => {

    }
    const getModulesData = (data) => {
        debugger;
        setmoduledropdown(data);

    }
    const getprivilegeData = (data) => {
        setprivilegedropdown(data)
    }
    const successGetPre = data => {
        formik.setFieldValue('description', data.description);
        formik.setFieldValue('privilegeType', data.type);
        formik.setFieldValue('modules', data.module);
        formik.setFieldValue('menus', data.menus);

    }
    useEffect(() => {
        getAllModules(getModulesData);
        getAllPrivilegeType(getprivilegeData);
        debugger;
        if (id && id !== '') {
            getAllRolesPrivilegeByID(successGetPre, id);
        }
    }, []);
    return (
        <AppBarDashboard>
            <div>
                <h3>Add Privilages</h3>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Input
                                {...formik.getFieldProps('description')}
                                id="description"
                                type="text"
                                labeltext="Description"
                                changehandler={handlechange}
                                helperText={descriptionerror}
                                error={descriptionValid}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DropDown
                                valuedropdown={privilegeType}
                                setvaluedropdown={setprivilegeType}
                                changehandler={handlechangeprivilegeType}
                                id="privilegeType"
                                labeltext="Privilege Type"
                                dropdownitems={privilegedropdown}
                                {...formik.getFieldProps('privilegeType')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DropDown
                                valuedropdown={modules}
                                setvaluedropdown={setmodules}
                                changehandler={handlemodules}
                                id="modules"
                                labeltext="Modules"
                                dropdownitems={moduledropdown}
                            // {...formik.getFieldProps('modules')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DropDown
                                valuedropdown={menus}
                                setvaluedropdown={setmenus}
                                changehandler={handlemenus}
                                id="menus"
                                labeltext="Menus"
                                dropdownitems={menudropdown}
                                {...formik.getFieldProps('menus')}
                                multiple={true}
                            />
                        </Grid>
                    </Grid>
                    <Btn
                        type="submit"
                        buttontext="Save"
                        clickhandler={handleclick}
                    />
                </form>
            </div>
        </AppBarDashboard>

    );
};
export default withRouter(AddPrivileges);
