import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useStyles } from "./subscription.style";
import AppBarDashboard from "../../molecules/appBar/appBar";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import TabContext from "@material-ui/lab/TabContext";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TabPanel from "@material-ui/lab/TabPanel";
import PackageDetails from "./packageDetails";
import EntityDetails from "./entityDetails";
import { showSuccessSnackbar } from "../../../stores/actions/snackBarActions";
import BranchDetails from "./branchDetails";
import {
  createEntity,
  createMarketPlace,
  getDevices,
  saveOnlyCheckOutSub,
  cashCheckOutSub,
  cardCheckOutSub,
} from "../../../services/subscriptionService/subscriptionService";
import CartItem from "../../atoms/cartItem/cartItem";
import { Grid } from "@material-ui/core";
import CheckoutSummary from "./checkOut";
import PaymentMethods from "./paymentMethods";
import AllBranches from "./getAllBranches";

const AddSubscription = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nevigate = useHistory();
  const [value, setValue] = React.useState(0);
  const [totalPayment, settotalPayment] = React.useState(0);
  const [pkgid, setpkgid] = React.useState("");
  const [entityid, setentityid] = React.useState("");
  const [devices, setDevices] = React.useState([]);
  const [devicesToAdd, setdevicesToAdd] = React.useState({});
  const [packageFull, setpackageFull] = React.useState({});
  const [proceed, setProceed] = React.useState(false);
  const [gotAllBranches, setgotAllBranches] = React.useState([]);
  const handleChange = (event, newValue) => {
    debugger;
    setValue(newValue);
  };
  const getDeviceSuccess = (data) => {
    debugger;
    setDevices(data);
  };
  const AddDeviceToCart = (item) => {
    debugger;
    setdevicesToAdd(item);
  };
  const handleSuccessAddDevice = (data) => {
    debugger;
    if (data.status) {
      dispatch(showSuccessSnackbar("Device Added successfully", "success"));
      setValue("4");
      document.getElementById("NxtBtnProcess").style.display = "none";
      document.getElementsByClassName("btnToShowAtEnd")[0].style.display =
        "block";
      document.getElementsByClassName("btnToShowAtEnd")[1].style.display =
        "block";
    } else {
      dispatch(showSuccessSnackbar(data.message, "error"));
    }
  };
  const NextStepSubs = (val) => {
    debugger;
    if (pkgid == "") {
        dispatch(showSuccessSnackbar("Please Select a Package", "warning"));
    }
    if (value == 0 && pkgid !== "") {
      let newval = parseInt(value) + 1;
      setValue(newval.toString());
    } else if (value == 1) {
      document.getElementById("EntitySubmission").click();
    } else if (value == 2) {
      if (gotAllBranches.length == 0) {
        dispatch(showSuccessSnackbar("Please Add a Branch First", "warning"));
      } else {
        setValue("3");
      }
    } else if (value == 3) {
      if (!(Object.keys(devicesToAdd).length === 0)) {
        createMarketPlace(devicesToAdd, handleSuccessAddDevice, entityid);
      }
    }
  };
  const successAddSubCashOnly = (data) => {
    if (data.status) {
      dispatch(
        showSuccessSnackbar("Subscription Added successfully", "success")
      );
      nevigate.push("/subscription");
    } else {
      dispatch(showSuccessSnackbar(data.message, "error"));
    }
  };
  const successProcessPayment = (data) => {
    if (data.status) {
      dispatch(
        showSuccessSnackbar("Subscription Added successfully", "success")
      );
      window.location.href =
        window.location.origin +
        "/paymentprocess.html?checkoutid=" +
        data.data[0].reference_id;
    } else {
      dispatch(showSuccessSnackbar(data.message, "error"));
    }
  };
  const saveOnlyCheckOut = () => {
    debugger;
    let tot = parseFloat(packageFull.price) + parseFloat(devicesToAdd.price);
    settotalPayment(tot);
    if (entityid !== "") {
      saveOnlyCheckOutSub(totalPayment, successAddSubCashOnly, entityid);
    }
  };
  const ProceedCashPayment = () => {
    debugger;
    cashCheckOutSub(totalPayment, successAddSubCashOnly, entityid);
  };
  const ProcessCardPayment = (card) => {
    debugger;
    cardCheckOutSub(totalPayment, successProcessPayment, card);
  };
  useEffect(() => {
    getDevices(getDeviceSuccess);
    setValue("0");
  }, []);
  return (
    <div>
      <AppBarDashboard>
        <div className={classes.root}>
          <TabContext value={value}>
            <AppBar position="static">
              <TabList
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
                className={classes.NavTabClass}
              >
                <Tab label="Package Details" value="0" />
                <Tab label="Entity Details" value="1" />
                <Tab label="Branch Details" value="2" />
                <Tab label="Devices Marketplace" value="3" />
                <Tab label="Check Out" value="4" />
              </TabList>
            </AppBar>
            <TabPanel value="0" index={0}>
              <PackageDetails packgid={setpkgid} pkg={setpackageFull} />
            </TabPanel>
            <TabPanel value="1" index={1}>
              <EntityDetails
                pkgid={pkgid}
                getentityid={setentityid}
                setNext={setValue}
              />
            </TabPanel>
            <TabPanel value="2" index={2}>
              <AllBranches allBranches={gotAllBranches} />
              <BranchDetails
                entityid={entityid}
                setNext={setValue}
                setAllBranches={setgotAllBranches}
              />
            </TabPanel>
            <TabPanel value="3" index={3}>
              <ShoppingCartIcon className={classes.ShoppingCartButton} />
              <Grid container spacing={4}>
                {devices.map((x) => {
                  return (
                    <>
                      <Grid item xs={3} className={classes.deviceBox}>
                        <CartItem item={x} handleAddToCart={AddDeviceToCart} />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </TabPanel>
            <TabPanel value="4" index={4}>
              {proceed == false ? (
                <CheckoutSummary packg={packageFull} device={devicesToAdd} />
              ) : (
                <PaymentMethods
                  totalAmount={totalPayment}
                  checkOutCash={ProceedCashPayment}
                  cardCheckOut={ProcessCardPayment}
                />
              )}
            </TabPanel>
            <Button
              className={classes.nxtbtn}
              type="submit"
              variant="contained"
              id="NxtBtnProcess"
              onClick={NextStepSubs}
              color="blue"
            >
              Next
            </Button>
            <Button
              className={[
                classes.nxtbtn,
                classes.submissionBtn,
                "btnToShowAtEnd",
              ]}
              onClick={saveOnlyCheckOut}
            >
              Save Only
            </Button>
            <Button
              className={[
                classes.nxtbtn,
                classes.submissionBtn,
                "btnToShowAtEnd",
              ]}
              onClick={() => {
                setProceed(true);
                document.getElementsByClassName(
                  "btnToShowAtEnd"
                )[0].style.display = "none";
                document.getElementsByClassName(
                  "btnToShowAtEnd"
                )[1].style.display = "none";
                let tot =
                  parseFloat(packageFull.price) +
                  parseFloat(devicesToAdd.price);
                settotalPayment(tot);
              }}
            >
              Proceed Check Out
            </Button>
          </TabContext>
        </div>
      </AppBarDashboard>
    </div>
  );
};
export default AddSubscription;
