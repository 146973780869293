import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import Login from "../pages/login/login";
import DashboardPOS from "../pages/dashboard/dashboard";
import { selectUser } from "../stores/reducers/userReducer";
import UserGrid from "../components/organisms/User/userGrid";
import AddUser from "../components/organisms/User/addUser";
import RolePrivilegesGrid from "../components/organisms/rolesPrivileges/rolePrivilegesGrid";
import AddPrivileges from "../components/organisms/rolesPrivileges/addPrivileges";
import ResetPassword from "../pages/login/resetPassword";
import FeatureGrid from "../components/organisms/features/featureGrid";
import SnackMessage from "../components/atoms/snackMessage/snackMessage";
import PackageGrid from "../components/organisms/packages/packageGrid";
import AddFeature from "../components/organisms/features/addFeature";
import ERecipt from "../pages/erecipt/eRecipt";
import AddPackage from "../components/organisms/packages/addPackage";
import SubscriptionGrid from "../components/organisms/subscription/subscriptionGrid";
import AddSubscription from "../components/organisms/subscription/addSubscription";
import SubscriptionDetails from "../components/organisms/subscription/subscriptionDetails";

const App = () => {
  const user = useSelector(selectUser);
  return (
    <div>
      <SnackMessage />
      <BrowserRouter>
        <Route
          path="/"
          exact
          render={() => {
            if (user === null) {
              return <Login />;
            } else {
              return <DashboardPOS />;
            }
          }}
        />
        <Route
          path="/dashboard"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <DashboardPOS />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          path="/users"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <UserGrid />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          path="/adduser"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <AddUser />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          path="/rolesprivileges"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <RolePrivilegesGrid />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          path="/addrolesprivileges"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <AddPrivileges />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route path="/resetpassword" exact strict component={ResetPassword} />
        <Route
          path="/features"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <FeatureGrid />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          path="/packages"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <PackageGrid />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          path="/addfeature"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <AddFeature />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          path="/addpackage"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <AddPackage />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route path="/erecipt/:id" exact strict component={ERecipt} />
        <Route
          path="/subscription"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <SubscriptionGrid />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          path="/addsubscription"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <AddSubscription />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
        <Route
          path="/detailsubscription/:id"
          exact
          strict
          render={() => {
            if (user !== null) {
              return <SubscriptionDetails />;
            } else {
              return <Redirect to="/" />;
            }
          }}
        />
      </BrowserRouter>
    </div>
  );
};
export default App;
