import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useStyles } from "./subscription.style";

const AllBranches = ({ allBranches }) => {
  const classes = useStyles();
  useEffect(() => {}, [allBranches]);
  return (
    <>
    <h3>Branches List</h3>
      <Grid container className={classes.AddedBranches} spacing={5}>
          <Grid item xs={4}>
            Description
          </Grid>
          <Grid item xs={4}>
            Address
          </Grid>
          <Grid item xs={4}>
            Operator Name
          </Grid>
        </Grid>
      {allBranches.map((x) => {
        return (
          <Grid className={classes.AddedBranches} container spacing={5}>
            <Grid item xs={4}>
              {x.description}
            </Grid>
            <Grid item xs={4}>
              {x.address}
            </Grid>
            <Grid item xs={4}>
              {x.operator_name}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default AllBranches;
